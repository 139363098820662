import React from 'react';
import type { BasicComponentProps, RadioInputOption } from '../../types';
import RadioSwatches from '../RadioSwatches/RadioSwatches';

export type SwatchOptionSelectProps = {
  name: string;
  options?: RadioInputOption[] | null;
  onChange?: (value: string) => void;
  onHover?: (value: string) => void;
  value?: string;
  size?: 'small' | 'medium' | 'large';
  hideTooltips?: boolean;
  useExternalState?: boolean;
  showAllOptions?: boolean;
} & BasicComponentProps;

export function SwatchOptionSelect({
  name,
  options,
  onHover,
  onChange,
  value,
  size,
  hideTooltips,
  className,
  useExternalState,
  showAllOptions,
  ...props
}: SwatchOptionSelectProps): JSX.Element | null {
  if (!options?.length) return null;

  const radioOptions = options.map((option) => {
    return {
      crossOut: option.crossOut,
      disabled: option.disabled,
      label: option.label,
      style: option.background
        ? { background: option.background, backgroundSize: 'cover' }
        : undefined,
      value: option.value,
    };
  });

  return (
    <RadioSwatches
      className={className}
      name={name}
      onChange={onChange}
      onHover={onHover}
      options={radioOptions}
      showAllOptions={showAllOptions}
      showToolTips={!hideTooltips}
      size={size}
      useExternalState={useExternalState}
      value={value}
      {...props}
    />
  );
}

'use client';
import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import CloseIcon from '../../icons/close';

export interface ModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  showCloseIcon?: boolean;
  onClose?: () => void;
  blurBackground?: boolean;
  backgroundImageStyles?: string;
  headerContent?: React.ReactNode;
  disableClose?: boolean;
  title?: string;
  dataTestId?: string;
}
export default function Modal({
  isOpen,
  setIsOpen,
  children,
  showCloseIcon = false,
  onClose,
  blurBackground = false,
  backgroundImageStyles,
  headerContent,
  disableClose,
  title,
  dataTestId,
}: ModalProps): JSX.Element {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent): void => {
      if (event.code === 'Escape') {
        if (onClose) {
          onClose();
        }
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useEffect(() => {
    if (!disableClose) {
      const handleClickOutside = (event): void => {
        if (!modalRef.current?.contains(event.target)) {
          handleClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [modalRef]);

  const handleClose = (): void => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div
      className={clsx(
        'z-modal fixed bottom-0 left-0 right-0 top-0 max-h-full w-full items-center justify-center',
        isOpen ? 'flex' : 'hidden',
        blurBackground ? 'backdrop-blur-sm' : 'bg-black/50',
      )}
      data-testid={dataTestId}
      id='default-modal'
      role='dialog'
      tabIndex={-1}
      title={title}
    >
      <div
        className={clsx(
          'relative flex max-h-full flex-col rounded-lg bg-white bg-cover bg-no-repeat pt-14 md:max-h-[80vh] md:px-8 md:pb-8',
          backgroundImageStyles,
        )}
        ref={modalRef}
      >
        {headerContent || showCloseIcon ? (
          <div
            className={clsx(
              'absolute left-0 right-0 top-0 m-auto flex justify-end rounded-t-lg p-2',
              backgroundImageStyles ? '' : 'bg-white',
            )}
          >
            <div className='flex w-full items-center'>{headerContent}</div>
            {!disableClose && showCloseIcon ? (
              <button
                aria-label='Close modal'
                className='flex items-center justify-center rounded-full bg-black p-2'
                onClick={handleClose}
                type='button'
              >
                <CloseIcon pathStroke='white' />
              </button>
            ) : null}
          </div>
        ) : null}
        <div className='overflow-y-auto overflow-x-hidden'>{children}</div>
      </div>
    </div>
  );
}

import React from 'react';
import clsx from 'clsx';
import type { BasicComponentProps } from '../../types';
import Icon from '../Icon/Icon';
import styles from './thumbnailSelect.module.scss';

export type ThumbnailSelectProps = {
  value?: number;
  onChange?: (index: number) => void;
  showArrowButtons?: boolean;
} & BasicComponentProps;

const widthsByImageCount = {
  1: 'w-[52px] md:w-[84px]',
  2: 'w-[104px] md:w-[168px]',
  3: 'w-[156px] md:w-[252px]',
  4: 'w-[208px] md:w-[336px]',
  5: 'w-[208px] md:w-[420px]',
};

export function ThumbnailSelect({
  value = 0,
  onChange,
  className,
  children,
  showArrowButtons,
  ...props
}: ThumbnailSelectProps): JSX.Element {
  const childrenArray = React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  ) as React.ReactElement[];

  const thumbnailsStyle = {
    '--image-index': value,
    '--image-count': childrenArray.length,
  } as React.CSSProperties;

  const handleChange = (newValue: number) => () => {
    onChange && onChange(newValue);
  };

  return (
    <div
      className={clsx(
        styles.thumbnailContainer,
        'flex w-full items-center justify-center',
        '[&>button>*]:h-auto [&>button>*]:w-auto',
        '[&>button]:border-sm [&>button]:text-gray-b50 [&>button]:h-[52px] [&>button]:transition-colors [&>button]:duration-[400ms]',
        '[&>button:before]:py-![50%] [&>button:disabled]:invisible [&>button:hover]:text-black',
        'md:[&>button]:h-[84px]',
        className,
      )}
      {...props}
    >
      <button
        disabled={!showArrowButtons || value === 0}
        onClick={handleChange(value - 1)}
        type='button'
      >
        <Icon
          alt='previous image'
          color='currentColor'
          size='small'
          variant='caret_left'
        />
      </button>
      <div
        className={clsx(
          styles.thumbnails,
          'flex items-center justify-start overflow-hidden',
          '[&>button]:relative [&>button]:m-1 [&>button]:block [&>button]:cursor-pointer [&>button]:select-none [&>button]:rounded-sm [&>button]:border-2 [&>button]:border-transparent [&>button]:transition-colors md:[&>button]:m-2',
          '[&>button>img]:object-contain',
          widthsByImageCount[childrenArray.length] ?? widthsByImageCount[5],
        )}
        style={thumbnailsStyle}
      >
        {childrenArray.map((child, index) => (
          <button
            className={clsx(index === value ? '!border-black' : '')}
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            onClick={handleChange(index)}
            type='button'
          >
            {child}
          </button>
        ))}
      </div>
      <button
        disabled={!showArrowButtons || value === childrenArray.length - 1}
        onClick={handleChange(value + 1)}
        type='button'
      >
        <Icon
          alt='next image'
          color='currentColor'
          size='small'
          variant='caret_right'
        />
      </button>
    </div>
  );
}

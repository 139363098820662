import React from 'react';
import clsx from 'clsx';
import { Price } from '../Price/Price';
import Icon from '../Icon/Icon';
import styles from './productHeader.module.scss';

export interface ProductHeaderProps {
  title: string;
  price: string;
  originalPrice?: string;
  isProDiscount?: boolean;
  withWhoopProPrice?: string;
  fancy?: boolean;
  size?: HeaderSize;
  subTitle?: string;
  reviewStars?: React.ReactNode;
}

export type HeaderSize = 'small' | 'medium' | 'large';
const headerStylesBySize = {
  small: 'text-xs',
  medium: 'text-sm',
};

const h1StylesBySize = {
  small: 'text-xs tracking-[1.4px] mr-2',
  medium: 'text-base mr-2',
};

/**
 * Product Header component. Used as a header for any product detail
 * page or product detail modal. It supports showing a price and
 * discount price, as well as showing potential price "with WHOOP Pro".
 * These configurations are all possible based on what properties are provided.
 *
 * For instance, providing a `price` & `originalPrice` will show the price as
 * discounted, but if you pass `isProDiscount={true}` then it will show the
 * price as a WHOOP Pro discounted price.
 *
 * For this reason, users of this component need to be careful of what prices
 * to pass based on the users WHOOP Pro state.
 *
 * Prices are just strings, so users must also format prices properly.
 *
 * @param title product title. Will be uppercase.
 * @param price formatted price string
 * @param originalPrice crossed out price or original price if WHOOP Pro
 * @param isProDiscount if the price is a WHOOP Pro price
 * @param withWhoopProPrice CTA "$X with PRO" price (will not show if isProDiscount)
 * @param size
 * @param subTitle
 * @param fancy sets a color gradient on the text
 * @param reviewStars
 */
export function ProductHeader({
  title,
  price,
  originalPrice,
  withWhoopProPrice,
  isProDiscount,
  size = 'large',
  subTitle,
  fancy,
  reviewStars,
}: ProductHeaderProps): JSX.Element {
  return (
    <div
      className={clsx(
        'relative text-left',
        headerStylesBySize[size],
        size === 'large' ? 'text-center md:text-left' : '',
      )}
    >
      <div
        className={clsx(
          'flex flex-row flex-wrap justify-start',
          size === 'large' ? 'justify-center md:justify-start' : '',
        )}
      >
        <h1
          className={clsx(
            'font-whoopSemibold mb-[18px] text-[40px] leading-10 tracking-[2.0px]',
            h1StylesBySize[size],
            fancy ? 'linear-gradient(90deg,_#5E22DC_0.53%,_#0D95B4_97.4%)' : '',
            fancy ? styles.fancy : '',
          )}
        >
          {title}
        </h1>
      </div>
      {price ? (
        <div className={clsx('flex items-center justify-start')}>
          <Price
            isProDiscount={isProDiscount}
            originalPrice={originalPrice}
            price={price}
            size={size}
          />
          {subTitle ? (
            <h3
              className={clsx(
                'text-blue-strain pl-3 text-base font-semibold leading-4',
              )}
            >
              {subTitle}
            </h3>
          ) : undefined}
          {!isProDiscount && withWhoopProPrice ? (
            <div
              className={clsx(
                'ml-3 flex items-center justify-center',
                '[&_svg]:color-black [&_svg]:ml-1 [&_svg]:h-5 [&_svg]:w-5',
                '[&>span]:first:text-gray-a700 [&>span]:first:text-sm [&>span]:first:font-semibold  [&>span]:first:tracking-widest',
              )}
              data-test-id='with-pro-price'
            >
              <span>{withWhoopProPrice} with</span>
              <span data-tooltip='Save 20% with WHOOP Pro'>
                <Icon alt='whoop pro logo' variant='logo_pro' />
              </span>
            </div>
          ) : undefined}
        </div>
      ) : undefined}
      <div className='py-1'> {reviewStars}</div>
    </div>
  );
}

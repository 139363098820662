import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { addRipple } from '../../utils';

const BUTTON_STYLES =
  'h-9 w-9 cursor-pointer border-0 bg-transparent text-base disabled:text-gray-500';

interface NumberInputProps {
  name?: string;
  stepSize?: number;
  min?: number;
  max?: number;
  allowDecimals?: boolean;
  disabled?: boolean;
  onChange?: (value: number | string) => void;
  useExternalState?: boolean;
  value: number;
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      min,
      max,
      name,
      allowDecimals = false,
      stepSize = 1,
      disabled = false,
      onChange,
      value,
      useExternalState = false,
      ...props
    }: NumberInputProps,
    inputRef,
  ): JSX.Element => {
    // ---Initialize ---
    // set value when parent updates value
    useEffect(() => setValue(clampValue(value, max, min)), [value, max, min]);
    const $container = useRef<HTMLDivElement>(null);
    const $input = useRef<HTMLInputElement>(null);

    // Clamps value based on props, returns 0 if value is null
    const clampValue = (
      value: number | null,
      max?: number,
      min?: number,
    ): number => {
      let newValue: number;
      if (!value || value < 0) {
        newValue = 0;
      } else {
        newValue = value;
        if (min !== undefined) {
          newValue = Math.max(value, min);
        }
        if (max !== undefined) {
          newValue = Math.min(value, max);
        }
      }
      return newValue;
    };
    const [valueState, setValue] = useState(clampValue(value, max, min));
    const actualValue = useExternalState
      ? clampValue(value, max, min)
      : valueState;

    // Updates the value and clamps to min & max
    const updateValue = (newValue: number | string): void => {
      let value: string | number | null = newValue;
      // set to integer if that flag is enabled
      if (!allowDecimals && !Number.isInteger(value)) {
        value = parseInt(value as string);
      }
      // strip min & max
      value = clampValue(value as number, max, min);
      if (actualValue !== value) {
        // only update if there the new value is different
        // change div size
        setValue(value);
        if (onChange) onChange(value);
      }
    };

    // Called when the user edits the textbox
    const onTextChangeHandler = (event): void => {
      updateValue(event.target.value);
    };

    // Called when the user clicks + OR -
    const onClickHandler = (event, multiplier): void => {
      if ($container.current)
        addRipple(
          $container.current,
          event,
          'bg-gray-100 opacity-0 pointer-events-none absolute rounded-full p-1 animate-ripple',
        );
      const newValue = actualValue + stepSize * multiplier;
      updateValue(newValue);
    };

    // --- Render ---
    return (
      <div
        className='relative flex w-fit flex-nowrap overflow-hidden rounded-sm border border-gray-300 font-semibold uppercase tracking-widest text-black focus-within:border-black'
        ref={$container}
        {...props}
      >
        <button
          className={BUTTON_STYLES}
          disabled={actualValue === min || disabled}
          onClick={(event) => onClickHandler(event, -1)}
          type='button'
        >
          -
        </button>
        <input
          className='m-auto box-border w-9 text-center text-base [appearance:textfield] focus:outline-none disabled:bg-transparent disabled:text-gray-500 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
          disabled={disabled}
          name={name}
          onChange={onTextChangeHandler}
          pattern='[0-9]*'
          ref={inputRef ?? $input}
          type='number'
          value={actualValue}
        />
        <button
          className={BUTTON_STYLES}
          disabled={actualValue === max || disabled}
          onClick={(event) => onClickHandler(event, 1)}
          type='button'
        >
          +
        </button>
      </div>
    );
  },
);

NumberInput.displayName = 'NumberInput';
export default NumberInput;

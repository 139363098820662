'use client';

import type { HTMLAttributes } from 'react';
import { useState } from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import { Button } from '../../ButtonV2/Button';
import type {
  AnyProduct,
  AccessoryProduct,
  DeviceProduct,
  MembershipProduct,
  WhoopYourWayProduct,
  EngravingProduct,
  PackProduct,
} from '../../../types';
import Modal from '../../Modal/Modal';
import NumberInput from '../../NumberInput/NumberInput';

interface AddToCartModalProps extends HTMLAttributes<HTMLDivElement> {
  children: JSX.Element;
  defaultQuantity?: number;
  headerContent?: React.ReactNode;
  isDisabled?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isUpdate?: boolean;
  onAddToCart:
    | ((product: AccessoryProduct, quantity: number) => void)
    | ((product: DeviceProduct, quantity: number) => void)
    | ((product: MembershipProduct, quantity: number) => void)
    | ((product: WhoopYourWayProduct, quantity: number) => void)
    | ((product: EngravingProduct, quantity: number) => void)
    | ((product: PackProduct, quantity: number) => void);
  selectedProduct?: AnyProduct;
  title?: string;
}

export default function AddToCartModal({
  children,
  defaultQuantity,
  headerContent,
  isDisabled,
  isOpen,
  setIsOpen,
  isUpdate,
  onAddToCart,
  selectedProduct,
  title,
}: AddToCartModalProps): JSX.Element {
  const { t } = useTranslation('accessoriesPage');
  const [quantity, setQuantity] = useState(defaultQuantity ?? 1);

  const onQuantityChange = (quantity: number | string): void => {
    setQuantity(Number(quantity));
  };

  const reset = (): void => {
    setQuantity(1);
  };

  const addToCart = (): void => {
    if (!selectedProduct) return;
    // @ts-expect-error - GRO-1428
    onAddToCart(selectedProduct, quantity);
    reset();
    setIsOpen(false);
  };

  const addToCartDisabled =
    isDisabled || !selectedProduct?.inventory_information?.max_quantity;
  const buttonLabel = isUpdate ? t('update') : t('addToCart');

  return (
    <Modal
      headerContent={headerContent}
      isOpen={isOpen}
      onClose={reset}
      setIsOpen={setIsOpen}
      showCloseIcon
      title={title}
    >
      {children}
      <div className='border-a400 sticky bottom-0 z-10 flex items-center justify-between border-t-2 bg-white p-2 shadow-md md:bottom-[-1px]'>
        <NumberInput
          max={selectedProduct?.inventory_information?.max_quantity ?? 1}
          min={1}
          onChange={onQuantityChange}
          value={quantity}
        />
        <Button
          className='flex max-w-[150px] justify-center'
          disabled={addToCartDisabled}
          onClick={addToCart}
          size='medium'
          variant='bright'
        >
          {buttonLabel}
        </Button>
      </div>
    </Modal>
  );
}

/* global HTMLDivElement, HTMLElement, JSX, Partial */
import React, { useState } from 'react';
import clsx from 'clsx';
import { AccordionThemes } from '../../types/Accordions';
import CaretUp from '../../icons/icons/Navigation/CaretUp';
import CaretDown from '../../icons/icons/Navigation/CaretDown';
import styles from './accordion.module.scss';

export interface AccordionSection {
  id: string;
  title: string;
  content: React.ReactNode;
  useLeftIcon?: boolean;
}

export interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  sections: AccordionSection[];
  onSectionOpen?: (section: Partial<AccordionSection>) => void;
  onSectionClose?: (section: Partial<AccordionSection>) => void;
  theme?: AccordionThemes;
  hideBorders?: boolean;
  className?: string;
  autoClose?: boolean;
}

/**
 * Todo: Use tailwind
 * Todo: Add tests
 * Component to display content inside of accordions
 * @deprecated Please use AccordionV3 instead
 */
export function Accordion({
  sections,
  onSectionOpen,
  onSectionClose,
  theme = AccordionThemes.DEFAULT,
  className,
  hideBorders = false,
  autoClose = true,
  ...props
}: AccordionProps): JSX.Element {
  const [openSectionIds, setOpenSectionIds] = useState<string[]>([]);

  function isSectionOpen(pageId: string): boolean {
    return openSectionIds.includes(pageId);
  }

  function toggleSection(
    id: string,
    title: string,
    content: React.ReactNode,
  ): void {
    if (openSectionIds.includes(id)) {
      const newOpenSectionIds = openSectionIds.filter((sectionId) => {
        return sectionId !== id;
      });
      setOpenSectionIds(newOpenSectionIds);
      onSectionClose && onSectionClose({ id, title, content });
    } else {
      const newOpenSectionIds = autoClose ? [id] : [...openSectionIds, id];
      setOpenSectionIds(newOpenSectionIds);
      onSectionOpen && onSectionOpen({ id, title, content });
    }
  }

  return (
    <div
      className={clsx(
        'mx-auto',
        theme !== AccordionThemes.DEFAULT ? `theme-${theme}` : '',
        className,
      )}
      data-testid='accordion'
      {...props}
    >
      <div className='px-6 lg:pb-11 lg:pr-20'>
        {sections.map(
          ({ id, title, content, useLeftIcon = false, ...sectionProps }) => (
            <section
              className={clsx(
                'overflow-hidden !p-0',
                !hideBorders ? 'border-gray-40 border-y' : '',
              )}
              key={id}
              {...sectionProps}
            >
              <button
                className={clsx(
                  styles.collapsibleButton,
                  'm-0 mb-1 flex items-center justify-between overflow-visible border-t-0 py-4 text-lg font-medium uppercase leading-6 tracking-widest text-black transition-colors',
                  useLeftIcon ? 'pl-6' : 'pr-6',
                )}
                data-testid='section-title'
                onClick={() => toggleSection(id, title, content)}
                type='button'
              >
                {title}
                {theme === AccordionThemes.JOIN_FLOW ? (
                  isSectionOpen(id) ? (
                    <CaretUp
                      fill='black'
                      height={16}
                      title='close section'
                      width={16}
                    />
                  ) : (
                    <CaretDown
                      fill='black'
                      height={16}
                      title='open section'
                      width={16}
                    />
                  )
                ) : (
                  <span
                    aria-expanded={isSectionOpen(id)}
                    className={clsx(
                      styles.collapsiblePlus,
                      'absolute top-[calc(50%-(11px/2))] h-[11px] w-[11px]',
                      `before:absolute before:left-1/2 before:top-1/2 before:h-[1px] before:w-[11px] before:-translate-x-1/2 before:-translate-y-1/2 before:-rotate-90 before:transition-transform before:content-['']`,
                      `after:absolute after:left-1/2 after:top-1/2 after:h-[11px] after:w-[1px] after:-translate-x-1/2 after:-translate-y-1/2 after:-rotate-90 after:transition-transform after:content-['']`,
                      useLeftIcon ? 'left-0' : 'right-0',
                    )}
                  />
                )}
              </button>
              <div
                className={clsx(
                  styles.collapsibleInner,
                  'block max-h-0 overflow-hidden',
                  'border-t-0',
                  isSectionOpen(id) ? 'max-h-[1000px]' : '',
                )}
                data-testid='section-content'
              >
                <div className='[&:*]:first:mt-0 [&:*]:last:mb-0'>
                  {content}
                </div>
              </div>
            </section>
          ),
        )}
      </div>
    </div>
  );
}

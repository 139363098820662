/**
 * Adds a ripple given some parameters. Will also remove any existing ripples.
 * @param element element to add ripple to
 * @param event event for mouse coords
 * @param classNames ripple class name
 */
export const addRipple = (
  element: HTMLButtonElement | HTMLDivElement,
  event: React.MouseEvent<HTMLButtonElement>,
  classNames: string,
): void => {
  const ripples = element.getElementsByClassName(classNames);
  Array.from(ripples).forEach((rippleEl) => {
    const createdAt = rippleEl.getAttribute('data-created-at');
    if (!createdAt || parseInt(createdAt) + 1000 < Date.now()) {
      // ripple expires after 1 second
      rippleEl.remove();
    }
  });
  const diameter = Math.max(element.clientWidth, element.clientHeight);
  const radius = diameter / 2;

  const ripple = document.createElement('span');
  const elementRect = element.getBoundingClientRect();
  ripple.style.width = `${diameter}px`;
  ripple.style.height = `${diameter}px`;
  ripple.style.left = `${event.clientX - (elementRect.left + radius)}px`;
  ripple.style.top = `${event.clientY - (elementRect.top + radius)}px`;
  if (classNames)
    classNames
      .split(' ')
      .forEach((className) => ripple.classList.add(className));
  ripple.setAttribute('data-created-at', Date.now().toString());
  element.append(ripple);
};

'use client';

import React, { useEffect, useRef } from 'react';
import 'flickity/css/flickity.css';
import Flickity from 'react-flickity-component';
import clsx from 'clsx';
import type { BasicComponentProps } from '../../types';

export type SlideshowProps = {
  value?: number; // current index of image to show
  pageDots?: boolean;
  prevNextButtons?: boolean;
  wrapAround?: boolean;
  onChange?: (index: number) => void;
  parentSizing?: boolean;
  multiImage?: boolean;
  overlay?: React.ReactNode;
  cellAlignForWrapAround?: boolean;
} & BasicComponentProps;

type FlickityElement = Flickity & {
  element: HTMLElement;
  isDraggable?: boolean;
};

let LOCKED_SLIDESHOWS: FlickityElement[] = [];

export function Slideshow({
  value,
  pageDots,
  prevNextButtons,
  wrapAround,
  children,
  className,
  onChange,
  parentSizing,
  multiImage,
  overlay,
  cellAlignForWrapAround,
  ...props
}: SlideshowProps): JSX.Element {
  const $flickity = useRef<FlickityElement>(null);

  useEffect(() => {
    if ($flickity.current) {
      // With the overlapping WYW images, flickity shows the front and back images overlapping unless the window is resized
      // A workaround is to trigger a "resize" event to get flickity to work without actually changing the window size
      window.dispatchEvent(new Event('resize'));

      $flickity.current.on('change', (index: number) => {
        onChange && onChange(index);
      });

      $flickity.current.on('dragStart', (event: MouseEvent) => {
        const path = event.composedPath();
        let slideshowCount = 0;
        path.forEach((_element: EventTarget) => {
          const element = _element as HTMLElement;
          if (
            element !== $flickity.current?.element &&
            // without this it will throw an error that element.className is not defined
            element?.className?.includes('flickity-enabled')
          ) {
            slideshowCount += 1;
          }
        });
        if (slideshowCount > 0) {
          // Lock this slideshow
          if ($flickity.current) {
            $flickity.current.isDraggable = false;
            LOCKED_SLIDESHOWS.push($flickity.current);
          }
        }
      });

      $flickity.current.on('dragEnd', () => {
        // unlock all slideshows
        LOCKED_SLIDESHOWS = LOCKED_SLIDESHOWS.filter(
          (flickity) => flickity.isDraggable === true,
        );
      });
    }
  }, [$flickity, onChange]);

  useEffect(() => {
    // value could be 0 for the first slide
    if ($flickity.current && typeof value === 'number') {
      $flickity.current.select(value);
    }
  }, [value]);

  return (
    <div
      className={clsx(
        'relative [&>button:disabled]:hover:opacity-0 [&>button]:hover:opacity-100 [&>div]:first:h-full',
        '[&>button:disabled]:invisible [&>button]:absolute [&>button]:top-0 [&>button]:flex [&>button]:h-full [&>button]:bg-white/50 [&>button]:transition-opacity [&>button]:duration-[400ms] [&>button]:ease-in-out',
        'md:[&>button]:block',
        '[&_img]:h-full [&_img]:max-h-full [&_img]:rounded-sm [&_img]:border-0 [&_img]:object-contain [&_img]:align-top',
        '[&_video]:h-full [&_video]:max-h-full [&_video]:rounded-sm [&_video]:border-0 [&_video]:object-contain [&_video]:align-top',
        '[&_.flickity-slider>*]:left-0 [&_.flickity-slider>*]:top-0 [&_.flickity-slider>*]:max-w-full',
        '[&_.flickity-page-dots]:opacity-0 [&_.flickity-viewport]:max-h-full',
        '[&_.flickity-prev-next-button]:text-gray-a700 [&_.flickity-prev-next-button:disabled]:!opacity-0 [&_.flickity-prev-next-button:hover]:text-black [&_.flickity-prev-next-button]:rounded-none [&_.flickity-prev-next-button]:bg-white [&_.flickity-prev-next-button]:opacity-100 [&_.flickity-prev-next-button]:duration-[400ms]',
        'md:[&_.flickity-prev-next-button]:opacity-0 md:[&_.flickity-prev-next-button]:transition-opacity md:[&_.flickity-prev-next-button]:hover:opacity-100',
        pageDots ? 'mb-9 [&_.flickity-page-dots]:opacity-100' : '',
        parentSizing ? '[&_.flickity-viewport]:!h-full' : '',
        className,
      )}
      {...props}
    >
      <Flickity
        flickityRef={(current: Flickity) => {
          // current.on('ready', () => window.dispatchEvent(new Event('resize')));
          // @ts-expect-error: Flickity library does not support a generic ref type for this
          $flickity.current = current;
        }}
        options={{
          adaptiveHeight: false,
          lazyLoad: true,
          dragThreshold: 8,
          initialIndex: 0,
          imagesLoaded: true,
          accessibility: true,
          setGallerySize: false,
          // this calculates the initial tranform wrong if the wrapAround and multiImage are true. Added the fix from this github issue
          // https://github.com/metafizzy/flickity/issues/1016
          cellAlign: multiImage
            ? cellAlignForWrapAround
              ? '0.01'
              : 'left'
            : 'center',
          pageDots,
          prevNextButtons,
          wrapAround,
          groupCells: 1,
          contain: multiImage,
        }}
      >
        {children}
      </Flickity>
      {overlay}
    </div>
  );
}

import React from 'react';
import clsx from 'clsx';
import Icon from '../Icon/Icon';

type PriceSizes = 'small' | 'medium' | 'large';

const sizeStyles = {
  small: 'text-sm',
  medium: 'text-lg',
  large: 'text-3xl',
};

export interface PriceProps {
  price: string;
  originalPrice?: string;
  isProDiscount?: boolean;
  ariaLabel?: string;
  size?: PriceSizes;
}

export function Price({
  price,
  originalPrice,
  isProDiscount,
  ariaLabel,
  size = 'small',
}: PriceProps): JSX.Element {
  return (
    <span
      aria-label={ariaLabel || 'Price'}
      className={clsx(
        'grid items-center gap-2 whitespace-nowrap leading-7 tracking-wide',
        originalPrice
          ? isProDiscount
            ? 'grid-cols-3'
            : 'grid-cols-2'
          : 'grid-cols-1',
        sizeStyles[size],
      )}
    >
      {originalPrice ? (
        <span className='text-inactive font-regular line-through'>
          {originalPrice}
        </span>
      ) : undefined}
      {originalPrice && isProDiscount ? (
        <Icon alt='whoop pro logo' size={size} variant='logo_pro' />
      ) : undefined}
      <span className='font-semibold'>{price}</span>
    </span>
  );
}
